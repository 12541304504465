import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchStores } from '../services'

export default function LinkForm({ register, errors, onlyEditable = false, setValue }) {
    const [selectedStore, setSelectedStore] = useState([])

    return (
        <React.Fragment>
            <Form.Group className='form-group'>
                <Form.Label>Punto de Venta <span className='text-danger'>*</span></Form.Label>
                <ServerSideSelect
                    value={selectedStore}
                    placeholder="Buscado por código (Ej: S20)"
                    fetchOptions={searchStores}
                    onChange={value => {
                        setSelectedStore(value)
                        setValue('store_id', value.value)
                    }}
                    className="full-width"
                />
                <Form.Control style={{ display:'none' }} {...register('store_id', {required:true})} />
                { errors.store_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Form.Group className='form-group'>
                <Form.Label>Referencia / Nombre del Link <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register('name', { required: true })}
                    placeholder='Escriba aquí...'
                />
                { errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
        </React.Fragment>
    )
}