import React, { useState } from 'react'
import { Alert, Button } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { Result, message } from 'antd'

import { publicStoreConversation } from '../../Conversations/services'
import { publicUpdateOpenLog } from '../../OpenLogs/services'
import { publicStoreLead } from '../../Leads/services'
import { handleError } from '../../../helpers'

export default function InitConversation({ openLogId, linkId, agent, initialMsg }) {
    const [loading, setLoading] = useState(false)
    const [mobile, setMobile] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const onSubmit = () => {
        if(Array.from(mobile)[0] !== '3' || mobile.length !== 10)
            return message.warning('Ingresa un celular válido de 10 dígitos')

        setLoading(true)

        if(agent){
            publicStoreLead({ mobile })
                .then(res => publicStoreConversation({
                    'link_id': linkId,
                    'lead_id': res.data.data.id,
                    'agent_id': agent.id,
                }))
                .then(res => publicUpdateOpenLog(openLogId, { 
                    'conversation_id': res.data.data.id 
                }))
                .then(res => {
                    setLoading(false)
                    setShowSuccessModal(true)
                    window.location.href = `https://wa.me/${agent.mobile}?text=${encodeURIComponent(initialMsg)}`
                })
                .catch(error => handleError(error, setLoading))
        } else {
            publicStoreLead({ mobile })
                .then(res => setShowSuccessModal(true))
                .catch(error => handleError(error, setLoading))
        }

    }

    if(showSuccessModal)
        return <Result status="success" title="Gracias, nuestros asesores se pondran en contacto." />

    return (
        <React.Fragment>
            { !agent && (
                <Alert color='warning'>{initialMsg}</Alert>
            )}
            <Form.Group className='form-group text-left mb-20'>
                <Form.Label className='fs-21'>Número de Whatsapp <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                    placeholder='Escribe tu número de whatsapp aquí'
                    type="number"
                />
                <Form.Text className='text-muted'>Te comunicaremos con el primer asesor disponible por medio del Whatsapp que nos indiques aquí. Tus datos serán tratados conforme a nuestra <a href='https://colchonesspring.vteximg.com.br/arquivos/politica-de-tratamiento-de-datos-spring-v-4.pdf?v=638173374098900000' target='_blank' rel="noreferrer">política de datos</a>.</Form.Text>
            </Form.Group>
            <Button color='primary' onClick={onSubmit} disabled={loading}>
                { loading && <Spinner animation='border' size="sm" /> }{" "}
                { agent ? 'Comenzar Chat de Whatsapp con Asesor' : 'Dejar solicitud de llamada' }
            </Button>
        </React.Fragment>
    )
}