import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAgentStatuses, storeAgentStatus } from '../../containers/AgentStatuses/services'
import { handleError } from '../../helpers'

export default function AgentStatusChip() {
    const currentUser = useSelector(state => state.auth.user)
    const [agentStatuses, setAgentStatuses] = useState(null)
    
    useEffect(() => {
        !agentStatuses && currentUser.role === 'agent' && getAgentStatuses({
            'filter[agent_id]': currentUser.id,
            'sort': '-created_at'
        })
            .then(res => setAgentStatuses(res.data.data))
            .catch(error => handleError(error))
    }, [ agentStatuses, currentUser ])

    const handleToggle = () => {
        const isActive = agentStatuses[0] && agentStatuses[0].is_active
        storeAgentStatus({
            agent_id: currentUser.id,
            is_active: isActive ? 0 : 1,
            is_system: 0
        })
            .then(() => setAgentStatuses(null))
            .catch(error => handleError(error))
    }

    if(currentUser.role !== 'agent' || !agentStatuses) return;

    return (
        <React.Fragment>
            <Container 
                active={agentStatuses[0] && agentStatuses[0].is_active}
                onClick={handleToggle}
            >
                {(agentStatuses[0] && agentStatuses[0].is_active) ? (
                    <p className='mb-0'>En Linea</p>
                ) : (
                    <p className='mb-0'>Fuera de Linea</p>
                )}
            </Container>
        </React.Fragment>
    )
}

const Container = styled.div`
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 100;
    background-color: ${props => props.active ? '#5db413' : '#333'};
    color: #fff;
    padding: 7px 15px;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.active ? '#488a0f' : '#888'};
    }
`