import React, { useState } from 'react'
import { Empty, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import EditLeadModal from './EditLeadModal'
import { number } from '../../../helpers'

export default function LeadsTable({ leads, reload }) {	
	const [editModal, setEditModal] = useState(null)

	const columns = [
		{
			title: 'Celular',
			dataIndex: 'mobile',
			render: t => t ? t : <em>Sin registro</em>
		},
		{
			title: 'Nombre Completo',
			dataIndex: 'name',
			render: t => t ? t : <em>Sin registro</em>
		},
		{
			title: 'Documento',
			dataIndex: 'document',
			render: t => t ? t : <em>Sin registro</em>,
			responsive: ['md'],
		},
		{
			title: 'Correo Electrónico',
			dataIndex: 'email',
			render: t => t ? t : <em>Sin registro</em>,
			responsive: ['md'],
		},
		{
			title: 'Total Chats',
			dataIndex: 'conversations_count',
			render: t => `${number(t)} chats`
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Actualizar Datos" className='text-link' onClick={() => setEditModal(r.id)}>
						<FontAwesomeIcon icon={faEdit} />{" "}
						<span className='hide-sm'>Editar</span>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={leads}
				rowKey={r => r.id}
				columns={columns}
				loading={!leads}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ editModal && (
				<EditLeadModal 
					visible
					onClose={() => setEditModal(null)}
					lead={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}