import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function LeadForm({ register, errors, onlyEditable = false }) {
    return (
        <React.Fragment>
            <Form.Group className='form-group'>
                <Form.Label>Nombre Completo <small>(opcional)</small></Form.Label>
                <Form.Control 
                    {...register('name', { required:false })}
                    placeholder='Escriba aquí...'
                />
                { errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Form.Group className='form-group'>
                <Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
                <Form.Control 
                    {...register('email', { required:false })}
                    placeholder='Escriba aquí...'
                />
                { errors.email && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group className='form-group' as={Col}>
                    <Form.Label>Documento <small>(opcional)</small></Form.Label>
                    <Form.Control 
                        {...register('document', { required:true })}
                        placeholder='Escriba aquí...'
                        type="number"
                    />
                    { errors.document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
                <Form.Group className='form-group' as={Col}>
                    <Form.Label>Celular <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('mobile', { required:true })}
                        disabled
                    />
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}