import springAvirtualApi from '../../services/springAvirtualApi'

export const getConversations = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/conversations', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getConversation = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/conversations/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeConversation = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/conversations', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateConversation = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/conversations/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteConversation = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/conversations/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicStoreConversation = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/public/conversations', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)