import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import LeadsTable from './partials/LeadsTable'

import { getLeads } from './services'
import { handleError } from '../../helpers'

export default function LeadsList() {
	const currentUser = useSelector(state => state.auth.user)
	const [leads, setLeads] = useState(null)
	const [filterType, setFilterType] = useState('mobile')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!leads && getLeads({
			[`filter[${filterType}]`]: filterValue,
			'filter[conversations.agent_id]': currentUser.role !== 'admin' ? currentUser.id : null,
			include: 'conversationsCount',
			sort: '-created_at'
		})
			.then(res => setLeads(res.data.data))
			.catch(error => handleError(error))
	}, [ leads, filterType, filterValue, currentUser ])

	return (
		<React.Fragment>
			<ListTopBar
				title="Prospectos Registrados"
				subtitle="Clientes potenciales que han iniciado conversaciones"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setLeads(null)}
				options={[
					{ label: 'Buscar por celular', value: 'mobile' },
					{ label: 'Buscar en nombre', value: 'name' },
					{ label: 'Buscar en documento', value: 'document' },
					{ label: 'Buscar en correo', value: 'email' },
				]}
			/>
			<Card>
				<CardBody className='overflow-scroll'>
					<LeadsTable 
						leads={leads}
						reload={() => setLeads(null)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}