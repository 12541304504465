import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { Result } from 'antd'

import UploadInvoice from './UploadInvoice'
import { updateConversation } from '../services'
import { handleError } from '../../../helpers'

export default function RegisterSaleModal({ visible, onClose, conversation, reload }) {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [currentStep, setCurrentStep] = useState(1)
    const [loading, setLoading] = useState(false)

    const onSubmit = values => {
        setLoading(true)

        updateConversation(conversation.id, values)
            .then(() => setCurrentStep(2))
            .catch(error => handleError(error, setLoading))
    }

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>Registrar Venta Conversación #{conversation.id}</ModalHeader>
            <ModalBody>
                { currentStep === 1 && (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='form-group'>
                            <Form.Label>Número o referencia de factura <span className='text-primary'>*</span></Form.Label>
                            <Form.Control 
                                {...register('invoice_ref', { required:true })}
                                placeholder='Escribe aquí...'
                            />
                            { errors.invoice_ref && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Form.Group className='form-group'>
                            <Form.Label>Valor Vendido <span className='text-primary'>*</span></Form.Label>
                            <Form.Control 
                                type="number"
                                {...register('invoice_value', { required:true })}
                                placeholder='Escribe aquí...'
                            />
                            { errors.invoice_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Button type="submit" color='primary' disabled={loading}>
                            { loading && <Spinner animation='border' size="sm" /> }{" "}
                            Registrar Datos
                        </Button>
                    </Form>
                )}
                { currentStep === 2 && (
                    <UploadInvoice 
                        conversationId={conversation.id} 
                        reload={() => setCurrentStep(3)}
                    />
                )}
                { currentStep === 3 && (
                    <Result 
                        status="success"
                        title="Venta Registrada Exitosamente"
                        subTitle="Gracias por ingresar la información"
                        extra={[
                            <Button color="primary" key="close" onClick={() => {
                                reload()
                                onClose()
                            }}>
                                Finalizar
                            </Button>
                        ]}
                    />
                )}
            </ModalBody>
        </Modal>
    )
}