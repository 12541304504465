import springAvirtualApi, { getDomain } from '../../services/springAvirtualApi'

export const getLinks = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/links', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getLink = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/links/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeLink = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/links', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateLink = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/links/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteLink = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/links/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchStores = async (term, token) => (
	fetch( getDomain()+`/stores?filter[code]=${term}&per_page=100`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(store => ({
				label: `(${store.code}) ${store.name}`,
				value: store.id,
			})),
		)
)

export const updateAgentLink = (agentId, linkId) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/agent_link/${agentId}/${linkId}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteAgentLink = (agentId, linkId) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/agent_link/${agentId}/${linkId}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)


export const publicGetLink = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/public/links/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)