import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Conversations from "./containers/Conversations"
import Leads from "./containers/Leads"
import Surveys from "./containers/Surveys"
import Links from "./containers/Links"
import Agents from "./containers/Agents"
import Users from "./containers/Users"
import QrLandings from "./containers/QrLandings"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/conversations" />

				<Route path="/auth" component={Authentication} />
				<Route path="/qr" component={QrLandings} />
				
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/conversations" component={Conversations} />
					<Route path="/leads" component={Leads} />
					<Route path="/surveys" component={Surveys} />
					<Route path="/links" component={Links} />
					<Route path="/agents" component={Agents} />
					<Route path="/users" component={Users} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}