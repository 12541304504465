import springAvirtualApi from '../../services/springAvirtualApi'

export const getOpenLogs = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/open_logs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getOpenLog = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/open_logs/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeOpenLog = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/open_logs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateOpenLog = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/open_logs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteOpenLog = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/open_logs/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicStoreOpenLog = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/public/open_logs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)


export const publicUpdateOpenLog = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/public/open_logs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)