import springAvirtualApi from '../../services/springAvirtualApi'

export const getSurveys = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/surveys', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSurvey = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/surveys/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSurvey = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/surveys', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSurvey = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/surveys/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSurvey = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/surveys/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicStoreSurvey = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/public/surveys', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)