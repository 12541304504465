import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import ConversationsTable from './partials/ConversationsTable'

import { getConversations } from './services'
import { handleError } from '../../helpers'

export default function ConversationsList() {
	const currentUser = useSelector(state => state.auth.user)
	const [conversations, setConversations] = useState(null)
	const [filterType, setFilterType] = useState('lead.mobile')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')

	useEffect(() => {
		!conversations && getConversations({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			'filter[agent_id]': currentUser.role !== 'admin' ? currentUser.id : null,
			include: 'link.store,lead,agent,quote,survey',
			sort: '-created_at'
		})
			.then(res => setConversations(res.data.data))
			.catch(error => handleError(error))
	}, [ conversations, filterType, filterValue, filterScope, currentUser ])

	return (
		<React.Fragment>
			<ListTopBar
				title="Conversaciones Registradas"
				subtitle="Historial de conversaciones iniciadas"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setConversations(null)}
				options={[
					{ label: 'Cédula asesor:', value: 'agent.document' },
					{ label: 'Celular asesor:', value: 'agent.mobile' },
					{ label: 'Cédula cliente:', value: 'lead.document' },
					{ label: 'Celular cliente:', value: 'lead.mobile' },
					{ label: 'Código de PDV:', value: 'link.store.code' },
				]}
				scopes={[
					{ label: 'Conversaciones Activas', value: 'active' },
					{ label: 'Conversaciones Canceladas', value: 'cancelled' },
					{ label: 'Conversaciones Finalizadas', value: 'completed' },
				]}
			/>
			<Card>
				<CardBody className='overflow-scroll'>
					<ConversationsTable 
						conversations={conversations}
						reload={() => setConversations(null)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}