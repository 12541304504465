import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LinksList from './LinksList'

export default function Links() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/links" component={LinksList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}