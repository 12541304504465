import React from 'react'
import AWS from "aws-sdk"
import { Form } from 'react-bootstrap'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { updateConversation } from '../services'

export default function UploadInvoice({ conversationId, reload }) {
	const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID
	const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

	const props = () => ({
		size: 'small',
		multiple: false,
		customRequest({
			file,
			onError,
			onProgress,
			onSuccess,
		}) {
			AWS.config.update({
				accessKeyId,
				secretAccessKey,
				Bucket: 'springsales',
				signatureVersion: 'v4',
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'springsales' },
				region: 'us-east-1',
		  	});

		  	let fileExt = file.name.split('.').pop()
		  	let uri = `avirtual/invoices/${Date.now()}.${fileExt}`
		
			const objParams = {
				Bucket: "springsales",
				Key: uri,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);
						updateConversation(conversationId, {
							invoice_url: `https://springsales.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => {
								message.success(res.data.message)
								reload()
							})
					}
				});
		}
	})

	return (
		<React.Fragment>
			<Form.Group className="mb-10">
				<Upload {...props()}>
					<Button icon={<UploadOutlined />}>Adjuntar Factura</Button>
				</Upload>
			</Form.Group>
		</React.Fragment>
	)
}