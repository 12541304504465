import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { QRCode, Result } from 'antd'

import { getClientUri } from '../../../services/springAvirtualApi'

export default function LinkQrModal({ visible, onClose, link }) {
    return (
        <React.Fragment>
            <Modal isOpen={visible} toggle={onClose}>
                <ModalHeader toggle={onClose}>QR para link #{link.id}</ModalHeader>
                <ModalBody>
                    <p><strong>Link: </strong>{link.name}</p>
                    { link.status.key === 'active' ? (
                        <QRCode 
                            value={`${getClientUri()}/#/qr/${link.id}`}
                            size={300}
                            icon='/favicon.png'
                            iconSize={35}
                        />
                    ) : (
                        <Result title="El link se encuentra inactivo" status="error" />
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}