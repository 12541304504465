import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faToggleOff, faToggleOn, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import LinkQrModal from './LinkQrModal'
import LinkAgentsModal from './LinkAgentsModal'

import { deleteLink, updateLink } from '../services'
import { handleError, number } from '../../../helpers'

export default function LinksTable({ links, reload }) {	
	const currentUser = useSelector(state => state.auth.user)
	const [qrModal, setQrModal] = useState(null)
	const [agentsModal, setAgentsModal] = useState(null)

	const columns = [
		{
			title: 'ID Link',
			dataIndex: 'id',
		},
		{
			title: 'Nombre del Link',
			dataIndex: 'name',
		},
		{
			title: 'Punto de Venta',
			dataIndex: 'store',
			render: t => t ? `${t.name} (${t.code})` : <em>Sin Registro</em>
		},
		{
			title: 'Total Aperturas',
			dataIndex: 'open_logs_count',
			render: t => `${t} aperturas`
		},
		{
			title: 'Total Conversaciones',
			dataIndex: 'conversations_count',
			render: t => `${number(t)} conversaciones`
		},
		{
			title: 'Total Asesores',
			dataIndex: 'agents',
			render: t => `${t.length} asesores`
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: (t,r) => (
				<React.Fragment>
					<Badge color={t.color}>{t.label}</Badge>
				</React.Fragment>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver QR" onClick={() => setQrModal(r)} className='text-link'>
						<FontAwesomeIcon icon={faQrcode} />{" "}
						Código QR
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Asesores Asociados" onClick={() => setAgentsModal(r)} className='text-link'>
						<FontAwesomeIcon icon={faUsers} />{" "}
						Asesores
					</Tooltip>
					<Divider type="vertical" />
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							{ !r.disabled_at ? (
								<Popconfirm
									title="¿Desea desactivar acceso al link?"
									okText="Desactivar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Desactivar" className='text-link'>
										<FontAwesomeIcon icon={faToggleOn} />{" "}
										Desactivar
									</Tooltip>
								</Popconfirm>
							) : (
								<Popconfirm
									title="¿Desea activar acceso al link?"
									okText="Activar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Activar" className='text-link'>
										<FontAwesomeIcon icon={faToggleOff} />{" "}
										Activar
									</Tooltip>
								</Popconfirm>
							)}
							{ r.conversations_count === 0 && (
								<React.Fragment>
									<Divider type='vertical' />
									<Popconfirm
										title="Esta acción no se puede revertir"
										okText="Elimnar"
										cancelText="Cancelar"
										okButtonProps={{ danger:true }}
										onConfirm={() => handleDelete(r.id)}
									>
										<Tooltip title="Eliminar" className='text-link'>
											<FontAwesomeIcon icon={faTrash} />{" "}
											Eliminar
										</Tooltip>
									</Popconfirm>
								</React.Fragment>
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleToggle = link => {
		updateLink(link.id, {
			disabled_at: link.disabled_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteLink(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={links}
				rowKey={r => r.id}
				columns={columns}
				loading={!links}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ qrModal && (
				<LinkQrModal 
					visible
					onClose={() => setQrModal(null)}
					link={qrModal}
				/>
			)}
			{ agentsModal && (
				<LinkAgentsModal 
					visible
					onClose={() => setAgentsModal(null)}
					link={agentsModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}