import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import AgentsTable from './partials/AgentsTable'
import NewAgentModal from './partials/NewAgentModal'

import { getAgents } from './services'
import { handleError } from '../../helpers'

export default function AgentsList() {
	const [agents, setAgents] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!agents && getAgents({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'conversationsCount',
			sort: '-created_at'
		})
			.then(res => setAgents(res.data.data))
			.catch(error => handleError(error))
	}, [ agents, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar
				title="Asesores Virtuales"
				subtitle="Listado de asesores virtuales habilitados"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setAgents(null)}
				options={[
					{ label: 'Buscar en nombre', value: 'name' },
					{ label: 'Buscar en documento', value: 'document' },
				]}
				scopes={[
					{ label: 'Asesores Activos', value: 'active' },
					{ label: 'Asesores Inactivos', value: 'disabled' },
				]}
				ctaText="Agregar Asesor Virtual"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<AgentsTable 
						agents={agents}
						reload={() => setAgents(null)}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewAgentModal 
					visible
					onClose={() => setShowNewModal(false)}
					agent={showNewModal}
					reload={() => setAgents(null)}
				/>
			)}
		</React.Fragment>
	)
}