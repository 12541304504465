import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import SurveysTable from './partials/SurveysTable'

import { getSurveys } from './services'
import { handleError } from '../../helpers'

export default function SurveysList() {
	const currentUser = useSelector(state => state.auth.user)
	const [surveys, setSurveys] = useState(null)
	const [filterType, setFilterType] = useState('conversation_id')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!surveys && getSurveys({
			[`filter[${filterType}]`]: filterValue,
			'filter[conversation.agent_id]': currentUser.role !== 'admin' ? currentUser.id : null,
			include: 'conversation.agent,conversation.link.store,conversation.lead',
			sort: '-created_at'
		})
			.then(res => setSurveys(res.data.data))
			.catch(error => handleError(error))
	}, [ surveys, filterType, filterValue, currentUser ])

	return (
		<React.Fragment>
			<ListTopBar
				title="Encuestas de satisfacción"
				subtitle="Encuestas relacionadas a conversaciones"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setSurveys(null)}
				options={[
					{ label: 'Buscar por ID de conversación', value: 'conversation_id' },
					{ label: 'Buscar por puntaje', value: 'score' },
				]}
			/>
			<Card>
				<CardBody className='overflow-scroll'>
					<SurveysTable 
						surveys={surveys}
						reload={() => setSurveys(null)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}