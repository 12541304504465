import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import AgentForm from './AgentForm'

import { updateAgent } from '../services'
import { handleError } from '../../../helpers'

export default function EditAgentModal({ visible, onClose, agent, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			name: agent.name,
			document: agent.document,
			mobile: agent.mobile,
			password: agent.password,
			auto_weekdays: agent.auto_weekdays,
			auto_start_at: agent.auto_start_at,
			auto_stop_at: agent.auto_stop_at,
			is_sales_expert: agent.is_sales_expert ? 1 : 0,
			is_sleep_expert: agent.is_sleep_expert ? 1 : 0,
			is_sac_expert: agent.is_sac_expert ? 1 : 0,
		}
	})

	const onSubmit = values => {
		setLoading(true)
		if(agent.document === parseInt(values.document)) delete values.document
		if(agent.mobile === parseInt(values.mobile)) delete values.mobile
		if(!values.password) delete values.password

		updateAgent(agent.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size='md'>
				<ModalHeader toggle={onClose}>Actualizar Asesor Virtual</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<AgentForm
							register={register}
							errors={errors}
							onlyEditable
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Asesor Virtual
						</Button>						
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}