import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Result } from 'antd'

import { publicStoreSurvey } from '../Surveys/services'
import { handleError } from '../../helpers'

export default function SurveyLanding(props) {
    const conversationId = props.match.params.id
    const [loading, setLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm()

    const onSubmit = values => {
        setLoading(true)
        publicStoreSurvey({
            'conversation_id': conversationId,
            ...values
        })
            .then(() => setIsSubmitted(true))
            .catch(error => handleError(error, setLoading))
    }

    if(isSubmitted) 
        return <Result status="success" title="Gracias por enviar tu respuesta" />

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit(onSubmit)} className='text-left'>
                <Form.Group className='form-group'>
                    <Form.Label>¿Qué tan bien califica la atención del asesor? <span className='text-danger'>*</span></Form.Label>
                    <div>
                        <FontAwesomeIcon 
                            icon={faStar} 
                            color={parseInt(watch('score')) >= 1 ? '#e42537' : '#d8d8d8'}
                            className='survey-star' 
                            onClick={() => setValue('score', 1)}
                        />
                        <FontAwesomeIcon 
                            icon={faStar} 
                            color={parseInt(watch('score')) >= 2 ? '#e42537' : '#d8d8d8'}
                            className='survey-star' 
                            onClick={() => setValue('score', 2)}
                        />
                        <FontAwesomeIcon 
                            icon={faStar} 
                            color={parseInt(watch('score')) >= 3 ? '#e42537' : '#d8d8d8'}
                            className='survey-star' 
                            onClick={() => setValue('score', 3)}
                        />
                        <FontAwesomeIcon 
                            icon={faStar} 
                            color={parseInt(watch('score')) >= 4 ? '#e42537' : '#d8d8d8'}
                            className='survey-star' 
                            onClick={() => setValue('score', 4)}
                        />
                        <FontAwesomeIcon 
                            icon={faStar} 
                            color={parseInt(watch('score')) >= 5 ? '#e42537' : '#d8d8d8'}
                            className='survey-star' 
                            onClick={() => setValue('score', 5)}
                        />
                    </div>
                    <Form.Control {...register('score', { required:true })} style={{ display: 'none' }} />
                    <Form.Text className='text-muted block'>Siendo 1 muy insatisfecho y 5 muy satisfecho</Form.Text>
                    { errors.score && <Form.Text className='text-warning'>Debe seleccionar un puntaje primero</Form.Text> }
                </Form.Group>
                <Form.Group className='form-group'>
                    <Form.Label>¿Tiene algún comentario al respecto? <small>(opcional)</small></Form.Label>
                    <Form.Control 
                        as="textarea"
                        {...register('observations', {})}
                        style={{ height: '100px' }}
                        placeholder='Escriba aquí sus observaciones'
                    />
                </Form.Group>
                <Button color='primary' type="submit" disabled={loading}>
                    { loading && <Spinner animation='border' size="sm" className='mr-5' /> }
                    Enviar Encuesta
                </Button>
            </Form>
        </React.Fragment>
    )
}