import React from 'react'
import { Empty, Table } from 'antd'

export default function SurveysTable({ surveys, reload }) {	

	const columns = [
		{
			title: 'No',
			dataIndex: 'id',
		},
		{
			title: 'Puntaje',
			dataIndex: 'score',
			render: t => `${t} de 5`
		},
		{
			title: 'Asesor',
			dataIndex: 'conversation',
			render: t => (t && t.agent) ? t.agent.name : <em>Sin registro</em>
		},
		{
			title: 'Punto de Venta',
			dataIndex: 'conversation',
			render: t => (t && t.link && t.link.store) ? t.link.store.name : <em>Sin registro</em>,
			responsive: ['md'],
		},
		{
			title: 'Celular Prospecto',
			dataIndex: 'conversation',
			render: t => t.lead.mobile,
		},
		{
			title: 'Fecha Encuesta',
			dataIndex: 'created_at',
			responsive: ['md'],
		},
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Observaciones: </strong>
				{r.observations}
			</p>
			<p className='mb-0'>
				<strong>Fecha Encuesta: </strong>
				{r.created_at}
			</p>
			<p className='mb-0'>
				<strong>Punto de Venta: </strong>
				{(r && r.conversation.link && r.conversation.link.store) ? r.conversation.link.store.name : <em>Sin registro</em>}
			</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={surveys}
				rowKey={r => r.id}
				columns={columns}
				loading={!surveys}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
				expandable={{ expandedRowRender }}
			/>
		</React.Fragment>
	)
}