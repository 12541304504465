import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import UsersTable from './partials/UsersTable'
import NewUserModal from './partials/NewUserModal'

import { getUsers } from './services'
import { handleError } from '../../helpers'

export default function UsersList() {
	const [users, setUsers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!users && getUsers({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			sort: '-created_at'
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [ users, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar
				title="Usuarios Administradores"
				subtitle="Usuarios con acceso al administrador"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setUsers(null)}
				options={[
					{ label: 'Buscar en nombre', value: 'name' },
					{ label: 'Buscar en documento', value: 'document' },
				]}
				scopes={[
					{ label: 'Usuarios Activos', value: 'active' },
					{ label: 'Usuarios Inactivos', value: 'disabled' },
				]}
				ctaText="Agregar Usuario"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<UsersTable 
						users={users}
						reload={() => setUsers(null)}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewUserModal 
					visible
					onClose={() => setShowNewModal(false)}
					user={showNewModal}
					reload={() => setUsers(null)}
				/>
			)}
		</React.Fragment>
	)
}