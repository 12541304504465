import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import LinkForm from './LinkForm'

import { storeLink } from '../services'
import { handleError } from '../../../helpers'

export default function NewLinkModal({ visible, onClose, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors }, setValue } = useForm()

	const onSubmit = values => {
		setLoading(true)
		storeLink(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size='md'>
				<ModalHeader toggle={onClose}>Agregar Link QR Virtual</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<LinkForm 
							register={register}
							errors={errors}
							setValue={setValue}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Crear Link QR
						</Button>						
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}