import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

import { publicAssignNextAgent } from '../../Agents/services'
import { handleError } from '../../../helpers'

export default function SelectAgent({ linkId, setAgent, setInitialMsg }) {
    const [loading, setLoading] = useState(false)

    const onSubmit = agentType => {
        setLoading(true)

        publicAssignNextAgent({ 
            'link_id': linkId,
            'agent_type': agentType
        })
            .then(res => {
                setInitialMsg(res.data.data.initial_message)
                setAgent(res.data.data.agent)
            })
            .catch(error => handleError(error, setLoading))
    }

    const options = [
        {
            type: 'sales_expert',
            title: 'Asesor Comercial',
            description: '¡Quiero comprar un colchón!'
        },
        {
            type: 'sac_expert',
            title: 'Servicio al Cliente Spring',
            description: 'Tengo una inquietud del servicio'
        },
    ]

    return (
        <React.Fragment>
            <p className='text-center mb-10'><em>Elige una opción según lo que buscas</em></p>
            { options.map(option => (
                <OptionContainer 
                    key={option.type}
                    onClick={() => !loading ? onSubmit(option.type): {}}
                    disabled={loading}
                >
                    <h6 className='mb-0 bold'>{option.title}</h6>
                    <p>{option.description}</p>
                </OptionContainer>
            )) }
            { loading && (
                <Spinner animation='border' size='sm' className='mt-20' />
            )}
        </React.Fragment>
    )
}

const OptionContainer = styled.div`
	border: 2px solid #dbe2f4;
	margin-bottom: 10px;
    padding: 15px 10px;
    background-color: ${props => !props.disabled ? '#fbfbfd' : '#d8d8d8'};
    opacity: ${props => !props.disabled ? '1' : '0.5'};
    p {
        margin-bottom: 0px;
        cursor: ${props => !props.disabled ? 'pointer' : 'not-allowed'};
    }
	&:hover {
		border: 2px solid #fff;
		cursor: ${props => !props.disabled ? 'pointer' : 'not-allowed'};
		color: #fff;
		background-color: #635ebe;
	}
    &:hover p {
        color: #fff;
    }
`