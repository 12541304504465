import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function UserForm({ register, errors, onlyEditable = false }) {
    return (
        <React.Fragment>
            <Form.Group className='form-group'>
                <Form.Label>Nombre Completo <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register('name', { required:true })}
                    placeholder='Escriba aquí...'
                />
                { errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Form.Group className='form-group'>
                <Form.Label>Documento <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register('document', { required:true })}
                    placeholder='Escriba aquí...'
                    type="number"
                />
                { errors.document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>
                        Contraseña {onlyEditable ? <small>(opcional)</small> : <span className='text-danger'>*</span>} 
                    </Form.Label>
                    <Form.Control 
                        {...register('password', { required: !onlyEditable })}
                        placeholder='Escriba aquí...'
                        type="password"
                    />
                    { errors.password && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>
                        Confirmar Contraseña {onlyEditable ? <small>(opcional)</small> : <span className='text-danger'>*</span>} 
                    </Form.Label>
                    <Form.Control 
                        {...register('password_confirmation', { required: !onlyEditable })}
                        placeholder='Escriba aquí...'
                        type="password"
                    />
                    { errors.password_confirmation && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}