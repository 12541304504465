import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ClientLayout from '../../layouts/ClientLayout'
import LinkLanding from './LinkLanding'
import SurveyLanding from './SurveyLanding'

export default function QrLandings() {
    return (
        <React.Fragment>
            <ClientLayout>
                <Switch>
                    <Redirect exact path='/qr' to="/404"/>

                    <Route exact path="/qr/:id" component={LinkLanding} />
                    <Route exact path="/qr/encuesta/:id" component={SurveyLanding} />

                    <Redirect to="/404" />
                </Switch>
            </ClientLayout>
        </React.Fragment>
    )
}