import springAvirtualApi, { getDomain } from '../../services/springAvirtualApi'

export const getAgents = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/agents', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getAgent = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/agents/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeAgent = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/agents', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateAgent = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/agents/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteAgent = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/agents/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchAgents = async (term, token) => (
	fetch( getDomain()+`/agents?filter[name]=${term}&per_page=100`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(agent => ({
				label: agent.name,
				value: agent.id,
			})),
		)
)

export const publicAssignNextAgent = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/public/assign_next_agent', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)