import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import LeadForm from './LeadForm'

import { updateLead } from '../services'
import { handleError } from '../../../helpers'

export default function EditLeadModal({ visible, onClose, lead, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			name: lead.name,
			document: lead.document,
			email: lead.email,
			mobile: lead.mobile,
		}
	})

	const onSubmit = values => {
		setLoading(true)
		if(lead.mobile === parseInt(values.mobile)) delete values.mobile

		updateLead(lead.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size='md'>
				<ModalHeader toggle={onClose}>Actualizar Prospecto</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<LeadForm
							register={register}
							errors={errors}
							onlyEditable
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Prospecto
						</Button>						
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}