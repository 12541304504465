import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LeadsList from './LeadsList'

export default function Leads() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/leads" component={LeadsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}