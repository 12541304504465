import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function AgentForm({ register, errors, onlyEditable = false }) {
    return (
        <React.Fragment>
            <Form.Group className='form-group'>
                <Form.Label>Nombre Completo <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register('name', { required:true })}
                    placeholder='Escriba aquí...'
                />
                { errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group className='form-group'>
                    <Form.Label>Documento <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('document', { required:true })}
                        placeholder='Escriba aquí...'
                        type="number"
                    />
                    { errors.document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
                <Form.Group className='form-group'>
                    <Form.Label>Celular <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('mobile', { required:true })}
                        placeholder='Escriba aquí...'
                        type="number"
                    />
                    { errors.mobile && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>
                        Contraseña {onlyEditable ? <small>(opcional)</small> : <span className='text-danger'>*</span>} 
                    </Form.Label>
                    <Form.Control 
                        {...register('password', { required: !onlyEditable })}
                        placeholder='Escriba aquí...'
                        type="password"
                    />
                    { errors.password && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>
                        Confirmar Contraseña {onlyEditable ? <small>(opcional)</small> : <span className='text-danger'>*</span>} 
                    </Form.Label>
                    <Form.Control 
                        {...register('password_confirmation', { required: !onlyEditable })}
                        placeholder='Escriba aquí...'
                        type="password"
                    />
                    { errors.password_confirmation && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
            </Row>
            <Form.Group className='form-group'>
                <Form.Label>Disponiblidad <span className='text-primary'>*</span></Form.Label>
                <Form.Control
                    as="select"
                    {...register('auto_weekdays', { required:true })}
                >
                    <option value={'1,2,3,4,5,6'}>Lunes a Sábado</option>
                    <option value={'1,2,3,4,5,6,0'}>Lunes a Domingo</option>
                    <option value={'1,2,3,4,5'}>Lunes a Viernes</option>
                    <option value={'6,0'}>Sábado y Domingo</option>
                </Form.Control>
            </Form.Group>
            <Row>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>Hora Auto-Inicio <span className='text-primary'>*</span></Form.Label>
                    <Form.Control
                        {...register('auto_start_at', { required:true })}
                        type="time"
                    />
                </Form.Group>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>Hora Auto-Final <span className='text-primary'>*</span></Form.Label>
                    <Form.Control
                        {...register('auto_stop_at', { required:true })}
                        type='time'
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>Asesor Comercial <span className='text-primary'>*</span></Form.Label>
                    <Form.Control
                        as="select"
                        {...register('is_sales_expert', { required:true })}
                    >
                        <option value={0}>No</option>
                        <option value={1}>Sí</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>Asesor SAC <span className='text-primary'>*</span></Form.Label>
                    <Form.Control
                        as="select"
                        {...register('is_sac_expert', { required:true })}
                    >
                        <option value={0}>No</option>
                        <option value={1}>Sí</option>
                    </Form.Control>
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}