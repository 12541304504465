import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import { Divider, Empty, Result, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { deleteAgentLink, getLink, updateAgentLink } from '../services'
import { searchAgents } from '../../Agents/services'
import { handleError } from '../../../helpers'

export default function LinkAgentsModal({ visible, onClose, link, reload }) {
    const [agents, setAgents] = useState(null)
    const [selectedAgent, setSelectedAgent] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        !agents && getLink(link.id, { include: 'agents' })
            .then(res => setAgents(res.data.data.agents))
            .catch(error => handleError(error))
    }, [ agents, link ])

    const handleSubmit = () => {
        setLoading(true)
        updateAgentLink(selectedAgent.value, link.id)
            .then(res => {
                message.success(res.data.message)
                setSelectedAgent([])
                setLoading(false)
                setAgents(null)
            })
            .catch(error => handleError(error, setLoading))
    }

    const handleDelete = agentId => {
        setLoading(true)
        deleteAgentLink(agentId, link.id)
            .then(res => {
                message.success(res.data.message)
                setSelectedAgent([])
                setLoading(false)
                setAgents(null)
            })
            .catch(error => handleError(error, setLoading))
    }

    const handleClose = () => {
        if(link.agents.length !== agents.length)
            reload()

        onClose()
    }

    return (
        <React.Fragment>
            <Modal isOpen={visible} toggle={handleClose}>
                <ModalHeader toggle={handleClose}>Agentes activos para link #{link.id}</ModalHeader>
                <ModalBody>
                    <p><strong>Link: </strong>{link.name}</p>
                    <Divider className='my-10' />
                    { link.status.key === 'active' ? (
                        <React.Fragment>
                            { agents ? (
                                <React.Fragment>
                                    { agents.length > 0 ? (
                                        <React.Fragment>
                                            { agents.map(agent => (
                                                <div key={agent.id} className='p-10 border'>
                                                    <FontAwesomeIcon icon={faUserCheck} />{" "}
                                                    <span>{agent.name}</span>
                                                    <Divider type='vertical' />
                                                    <span className='text-link' onClick={() => handleDelete(agent.id)}>
                                                        Desasociar
                                                    </span>
                                                </div>
                                            )) }
                                        </React.Fragment>
                                    ) : (
                                        <Empty description="Aún no tiene asesores asociados" />
                                    )}
                                </React.Fragment>
                            ) : <Spinner animation='border' size="sm" /> }
                            <InputGroup className='mt-20'>
                                <Form.Group>
                                    <ServerSideSelect
                                        value={selectedAgent}
                                        placeholder="Buscado por nombre del asesor"
                                        fetchOptions={searchAgents}
                                        onChange={value => setSelectedAgent(value)}
                                        className="full-width"
                                    />
                                </Form.Group>
                                <Button color='primary' onClick={handleSubmit} disabled={loading}>
                                    {loading && <Spinner animation='border' size="sm" />}{" "}
                                    Asociar Asesor Virtual
                                </Button>
                            </InputGroup>
                        </React.Fragment>
                    ) : (
                        <Result title="El link se encuentra inactivo" status="error" />
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}