import springAvirtualApi from '../../services/springAvirtualApi'

export const getAgentStatuses = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/agent_statuses', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getAgentStatus = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/agent_statuses/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeAgentStatus = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/agent_statuses', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateAgentStatus = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/agent_statuses/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteAgentStatus = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/agent_statuses/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)