import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import axios from "axios"

import SelectAgent from './partials/SelectAgent'
import InitConversation from './partials/InitConversation'

import { publicStoreOpenLog } from '../OpenLogs/services'
import { publicGetLink } from '../Links/services'
import { handleError } from '../../helpers'

export default function LinkLanding(props) {
    const linkId = props.match.params.id
    const [openLog, setOpenLog] = useState(null)
    const [agent, setAgent] = useState(null)
    const [initialMsg, setInitialMsg] = useState('')

    useEffect(() => {
        !openLog && publicGetLink(linkId)
            .then(res => {
                if(res.data.data.status.key !== 'active')
                    return window.location.replace('https://www.colchonesspring.com.co/')

                return axios.get('https://api.ipify.org')
            })
            .then(res => res && publicStoreOpenLog({ 
                'link_id': linkId,
                'user_ip': res.data,
                'user_agent': navigator.userAgent
            }))
            .then(res => res && setOpenLog(res.data.data))
            .catch(error => handleError(error))
    },[ openLog, linkId ])

    if(!openLog) return <Spinner animation='border' size="sm" />

    return (
        <React.Fragment>
            { !initialMsg ? (
                <SelectAgent 
                    linkId={linkId}
                    setAgent={setAgent} 
                    setInitialMsg={setInitialMsg}
                />
            ) : (
                <InitConversation 
                    openLogId={openLog.id}
                    linkId={linkId}
                    agent={agent}
                    initialMsg={initialMsg}
                />
            )}
        </React.Fragment>
    )
}