import springAvirtualApi from '../../services/springAvirtualApi'

export const getLeads = params => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get('/leads', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getLead = (id, params) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.get(`/leads/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeLead = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/leads', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateLead = (id, data) => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.put(`/leads/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteLead = id => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.delete(`/leads/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicStoreLead = data => (
	new Promise((resolve, reject) => {
		springAvirtualApi()
			.post('/public/leads', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)