import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import LinksTable from './partials/LinksTable'
import NewLinkModal from './partials/NewLinkModal'

import { getLinks } from './services'
import { handleError } from '../../helpers'

export default function LinksList() {
	const [links, setLinks] = useState(null)
	const [filterType, setFilterType] = useState('store.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!links && getLinks({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'store,conversationsCount,openLogsCount,agents',
			sort: '-created_at'
		})
			.then(res => setLinks(res.data.data))
			.catch(error => handleError(error))
	}, [ links, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar
				title="Links QR Asistente Virtual Spring"
				subtitle="Listado de links habilitados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setLinks(null)}
				options={[
					{ label: 'Buscar por nombre de tienda', value: 'store.name' },
					{ label: 'Buscar por codigo de tienda', value: 'store.code' },
					{ label: 'Buscar por ciudad', value: 'store.city' },
				]}
				scopes={[
					{ label: 'Links Activos', value: 'active' },
					{ label: 'Links Inactivos', value: 'disabled' },
				]}
				ctaText="Agregar QR Link"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<LinksTable 
						links={links}
						reload={() => setLinks(null)}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewLinkModal 
					visible
					onClose={() => setShowNewModal(false)}
					link={showNewModal}
					reload={() => setLinks(null)}
				/>
			)}
		</React.Fragment>
	)
}